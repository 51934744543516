import { createContext } from 'react'
import { Article, Catalog } from '@services/chematels/models/ranking'
import { MarketDataMonthToMonthBasis } from '@services/chematels/models/market'

type NewsContextValue = {
  marketDataMonthToMonthBasisList: MarketDataMonthToMonthBasis[]
  articles: Article[]
  catalogs: Catalog[]
}

export const NewsContext = createContext<NewsContextValue>({
  marketDataMonthToMonthBasisList: [],
  articles: [],
  catalogs: [],
})
