import React from 'react'
import axios from 'axios'
import { sortBy } from 'lodash'
import AppDrawer from '@components/Sidebar/AppDrawer'
import Footer from '@components/organisms/Footer'
import Navigation from '@components/organisms/Navigation'
import { initializeApollo } from '@graphql/apolloClient'
import { MarketApi } from '@infrastructure/market'
import {
  GetInformationTopDocument,
  GetArticlesDocument,
  GetAdDistributionManagementDocument,
  GetArticlesCategoryContainerDocument,
  GetPickupCatalogDocument,
  GetPickupCatalogQuery,
  AdDeliveryDestination,
} from '@services/graphcms/client'
import { Top } from '@containers/Home/Top'
import { NewsContext } from '@contexts/index'
import {
  Article as ArticleRanking,
  Catalog as CatalogRanking,
} from '@services/chematels/models/ranking'
import { Market, MarketDataMonthToMonthBasis } from '@services/chematels/models/market'
import {
  GetCatalogTopDocument,
  GetCatalogTopQuery,
  GetCatalogTopQueryVariables,
  Platform,
  OrderBy,
} from '@services/cloud/client'

type Props = React.ComponentProps<typeof Top> & {
  articlesRanking: ArticleRanking[]
  catalogsRanking: CatalogRanking[]
  market: Market
  marketDataMonthToMonthBasisList: MarketDataMonthToMonthBasis[]
}

export default function HomePage(props: Props) {
  return (
    <>
      <AppDrawer>
        <NewsContext.Provider
          value={{
            articles: props.articlesRanking,
            catalogs: props.catalogsRanking,
            marketDataMonthToMonthBasisList: props.marketDataMonthToMonthBasisList,
          }}
        >
          <Top {...props} />
        </NewsContext.Provider>
        <Footer />
        <Navigation />
      </AppDrawer>
    </>
  )
}

export async function getStaticProps() {
  const { data: dataRanking } = await axios.get(
    `${String(process.env.NEXT_PUBLIC_REACT_APP_FUNCTIONS)}/get_top_queries`,
  )
  const { marketDataMonthToMonthBasisList } = await new MarketApi().getMarketDataMonthToMonthBasis()
  const { marketData, marketMeta } = await new MarketApi().getMarketDataByName('naphtha')

  const apolloClient = initializeApollo()

  const nextSeoValues = {
    title: 'Chematels（ケマテルズ） | 化学業界に特化した総合情報サイト',
    description:
      'Chematelsは化学業界に特化したニュース、市況情報、製品カタログや企業情報を掲載している化学業界の総合情報サイトです。',
    canonical: 'https://chematels.com',
    openGraph: {
      url: 'https://chematels.com',
      title: 'Chematels（ケマテルズ） | 化学業界に特化した総合情報サイト',
      description:
        'Chematelsは化学業界に特化したニュース、市況情報、製品カタログや企業情報を掲載している化学業界の総合情報サイトです。',
    },
  }

  const {
    data: { pickupCatalogs },
  } = await apolloClient.query<GetPickupCatalogQuery>({
    query: GetPickupCatalogDocument,
  })

  const pickupCatalogIds = pickupCatalogs.map((catalog) => catalog.catalogId)

  const { data } = await apolloClient.query<GetCatalogTopQuery, GetCatalogTopQueryVariables>({
    query: GetCatalogTopDocument,
    variables: {
      args: {
        limit: 12,
        skip: 0,
        orderBy: OrderBy.Desc,
        where: {
          catalogIds: pickupCatalogIds ?? [],
          platform: Platform.Chematels,
        },
      },
    },
  })

  const sortedPickupCatalogs = sortBy(
    data?.pickupCatalogs?.map((catalog) => {
      const filteredPickupCatalog = pickupCatalogs
        .filter((pickupCatalog) => pickupCatalog.catalogId === catalog?.catalogId)
        .reduce((o) => o)

      return { ...filteredPickupCatalog, ...catalog }
    }),
    (o) => o.sort,
  )

  await apolloClient.query({
    query: GetArticlesDocument,
    variables: {
      skip: 0,
      first: 14,
    },
  })

  await apolloClient.query({
    query: GetArticlesCategoryContainerDocument,
  })

  await apolloClient.query({
    query: GetInformationTopDocument,
  })

  await apolloClient.query({
    query: GetAdDistributionManagementDocument,
    variables: {
      destination: AdDeliveryDestination.Top1,
    },
  })

  await apolloClient.query({
    query: GetAdDistributionManagementDocument,
    variables: {
      destination: AdDeliveryDestination.Top2,
    },
  })

  await apolloClient.query({
    query: GetAdDistributionManagementDocument,
    variables: {
      destination: AdDeliveryDestination.Top3,
    },
  })

  return {
    props: {
      marketDataMonthToMonthBasisList,
      market: {
        marketMeta,
        marketData,
      },
      articlesRanking: dataRanking?.articles ?? [],
      catalogsRanking: dataRanking?.catalogs ?? [],
      catalogs: data.catalogs,
      firstDepthCategories: data.firstDepthCategories,
      initialApolloState: apolloClient.cache.extract(),
      pickupCatalogs: sortedPickupCatalogs,
      nextSeo: nextSeoValues,
    },
    revalidate: 600,
  }
}
