import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel'
import { useMediaQuery } from '@mui/material/'

import { CarouselDots } from '@components/organisms/CarouselDots'
import { FeedCard } from '@components/organisms/Article/FeedCard'
import { ArticlesFragment } from '@services/graphcms/client'

const PREFIX = 'MainCarousel'

const classes = {
  buttonContainer: `${PREFIX}-buttonContainer`,
  buttonBack: `${PREFIX}-buttonBack`,
  buttonNext: `${PREFIX}-buttonNext`,
  dot: `${PREFIX}-dot`,
}

const StyledCarouselProvider = styled(CarouselProvider)(() => ({
  [`& .${classes.buttonContainer}`]: {
    position: 'relative',
  },

  [`& .${classes.buttonBack}`]: {
    backgroundColor: 'rgba( 255, 255, 255, 0.45 )',
    border: 'none',
    color: 'black',
    fontSize: '30px',
    left: 0,
    paddingBottom: '6px',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '35px',
  },

  [`& .${classes.buttonNext}`]: {
    backgroundColor: 'rgba( 255, 255, 255, 0.45 )',
    border: 'none',
    color: 'black',
    fontSize: '30px',
    paddingBottom: '6px',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: '35px',
  },

  [`& .${classes.dot}`]: {
    backgroundColor: 'white',
  },
}))

type Props = {
  stories: ArticlesFragment[]
}

export function MainCarousel(props: Props) {
  const theme = useTheme()
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <StyledCarouselProvider
      infinite={true}
      isPlaying={true}
      naturalSlideWidth={600}
      naturalSlideHeight={isMobileScreen ? 410 : 200}
      visibleSlides={1}
      totalSlides={props.stories.length}
    >
      <div className={classes.buttonContainer}>
        <Slider>
          {props.stories.map((story: any, index: number) => (
            <Slide key={index} index={index}>
              <FeedCard fullYear={true} article={story} />
            </Slide>
          ))}
        </Slider>
        <ButtonBack className={classes.buttonBack}>&#8249;</ButtonBack>
        <ButtonNext className={classes.buttonNext}>&#8250;</ButtonNext>
      </div>
      <CarouselDots totalSlides={props.stories.length} />
    </StyledCarouselProvider>
  )
}
