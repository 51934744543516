import React, { Fragment } from 'react'
import moment from 'moment'
import NextLink from 'next/link'
import { Container, Divider, Link, Paper, Typography } from '@mui/material'
import { useGetInformationTopQuery } from '@services/graphcms/client'

export function Informations() {
  const { data, error, loading } = useGetInformationTopQuery()

  if (error) {
    return <div>404 ページが存在しません</div>
  }

  if (loading) {
    return null
  }

  return (
    <Paper sx={{ mt: 1 }} elevation={0} variant="outlined">
      <Container sx={{ mt: 1 }}>
        <Typography align="center" sx={{ fontWeight: 'bold' }} gutterBottom={true} variant="h6">
          お知らせ
        </Typography>
        <Divider />
        {data?.informations &&
          data?.informations.map((information) => (
            <Fragment key={information.id}>
              <NextLink href={'/information/[id]'} as={`/information/${information.id}`} passHref>
                <Link
                  sx={{
                    color: 'black',
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  <Typography sx={{ mt: 1, mb: 1 }}>
                    {`${information.title} ${moment(information.publishDate).format('MM/DD h:mm')}`}
                  </Typography>
                </Link>
              </NextLink>
              <Divider />
            </Fragment>
          ))}
      </Container>
    </Paper>
  )
}
