import React from 'react'
import moment from 'moment'
import { Typography } from '@mui/material/'

type Date = {
  publishDate?: any
}

type Props = {
  article: Partial<Date>
  fullYear: boolean
}

export const Date = (props: Props) => (
  <Typography color="textSecondary" sx={{ mr: 1 }} variant="caption">
    {moment(props.article.publishDate).format(props.fullYear ? 'YYYY/MM/DD' : 'MM/DD HH:mm')}
  </Typography>
)
