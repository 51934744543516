import React from 'react'
import { useRouter } from 'next/router'
import truncate from 'truncate'
import { Card, CardActionArea, CardContent, Grid, Hidden, Typography } from '@mui/material/'
import { useIsThumbnail } from '@hooks/useIsThumbnail'
import { ArticlesFragment } from '@services/graphcms/client'
import { Date } from './Date'
import { Image } from './Image'

type Props = {
  fullYear: boolean
  article: ArticlesFragment
}

export function FeedCard(props: Props) {
  const router = useRouter()
  const isThumbnail = useIsThumbnail(props.article)

  return (
    <Card elevation={0}>
      <CardActionArea
        onClick={() => router.push(`/article/${props.article.id}`)}
        data-testid="feed-article-card"
      >
        <Grid container={true}>
          {isThumbnail && (
            <Grid item={true} xs={12} sm={7}>
              <Image
                article={props.article}
                styleImage={{
                  height: 0,
                  maxHeight: '400px',
                  paddingTop: '56.25%', // 16:9
                }}
              />
            </Grid>
          )}
          <Grid item={true} xs={12} sm={isThumbnail ? 5 : 12}>
            <CardContent>
              <Typography sx={{ fontWeight: 'bold' }} variant={isThumbnail ? 'h6' : 'h4'}>
                {props.article.title}
              </Typography>
              <Date article={props.article} fullYear={props.fullYear} />
              {props.article.author && (
                <div>
                  <Typography sx={{ fontWeight: 'bold' }} variant="caption">
                    {props.article.author.name}
                  </Typography>
                </div>
              )}
              <Hidden mdDown={true}>
                <Typography component="p" variant="body2">
                  {props.article.excerpt && truncate(props.article.excerpt, 54)}
                </Typography>
              </Hidden>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  )
}
