import React from 'react'
import dynamic from 'next/dynamic'
import ReactPlayer from 'react-player'
import { Card, CardContent, CardMedia, Typography } from '@mui/material/'
import {
  useGetAdDistributionManagementQuery,
  AdDistributionManagementFragment,
  AdDeliveryDestination,
} from '@services/graphcms/client'
import { Image } from '../Article/Image'

const DynamicActionArea = dynamic(import('./ActionArea'), { ssr: false })

type Props = {
  destination: AdDeliveryDestination
}

function FeedCardView(props: { ad: AdDistributionManagementFragment }) {
  if (!props.ad.content) {
    return null
  }

  return (
    <>
      {props.ad.content.thumbnail?.mimeType === 'video/mp4' ? (
        <CardMedia
          component={ReactPlayer}
          loop={true}
          muted={true}
          playing={true}
          playsinline={true}
          width="100%"
          height="100%"
          url={props.ad.content.thumbnail.url}
        />
      ) : (
        <Image
          article={props.ad.content}
          styleImage={{
            height: 0,
            maxHeight: '400px',
            paddingTop: '56.25%', // 16:9
          }}
        />
      )}
      <CardContent>
        <Typography sx={{ fontWeight: 'bold' }} variant="h6">
          {props.ad.content.title}
        </Typography>
        <Typography color="textSecondary" variant="caption">
          {`Sponsored by ${props.ad.content.sponsored}`}
        </Typography>
        <Typography component="p" variant="body2">
          {props.ad.content.description}
        </Typography>
      </CardContent>
    </>
  )
}

function AdFeedCard(props: Props) {
  const { data } = useGetAdDistributionManagementQuery({
    variables: {
      destination: props.destination,
    },
  })

  if (!data || !data.ad || !data.ad.content) {
    return null
  }

  return (
    <Card
      sx={{
        width: '300',

        '&:hover': {
          textDecoration: 'underline',
        },
      }}
      elevation={0}
      variant="outlined"
    >
      <React.Suspense fallback={<FeedCardView ad={data.ad} />}>
        <DynamicActionArea content={data.ad.content} destination={props.destination}>
          <FeedCardView ad={data.ad} />
        </DynamicActionArea>
      </React.Suspense>
    </Card>
  )
}

export default AdFeedCard
