import React from 'react'
import { AppBar, Container, Paper, Tab, Tabs, Typography } from '@mui/material'
import { RankingType } from '@services/chematels/models/ranking'
import { Ranking } from './Ranking'

function RankingTab() {
  const [value, setValue] = React.useState(0)

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Paper sx={{ mt: 1 }} elevation={0} variant="outlined">
      <Container sx={{ mt: 1 }}>
        <Typography align="center" sx={{ fontWeight: 'bold' }} gutterBottom={true} variant="h6">
          月間アクセスランキング
        </Typography>
      </Container>
      <AppBar
        sx={{ borderBottom: '2px solid #e8e8e8' }}
        position="static"
        color="inherit"
        elevation={0}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="記事" />
          <Tab label="カタログ" />
        </Tabs>
      </AppBar>
      <Ranking type={value === 0 ? RankingType.ARTICLE : RankingType.CATALOG} />
    </Paper>
  )
}

export default RankingTab
