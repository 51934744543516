import React, { useContext } from 'react'
import { useRouter } from 'next/router'
import { Box, Card, CardActionArea, Container, Divider, Grid, Typography } from '@mui/material'
import { Image } from '@components/organisms/Article/Image'
import { NewsContext } from '@contexts/index'
import { RankingTypes } from '@services/chematels/models/ranking'

type Props = {
  type: RankingTypes
}

export function Ranking(props: Props) {
  const { articles, catalogs } = useContext(NewsContext)
  const router = useRouter()

  if (!articles || !Array.isArray(articles) || !catalogs || !Array.isArray(catalogs)) {
    return null
  }

  // const ranking = rankings.filter((rank) => rank.type === props.type)
  const filteredArticles = articles.filter((_, index) => index < 10)
  const filteredCatalogs = catalogs.filter((_, index) => index < 10)

  return (
    <Container sx={{ marginTop: 1 }}>
      {props.type === 'ARTICLE'
        ? filteredArticles.map((article, index) => (
            <Card key={index} elevation={0}>
              <CardActionArea onClick={() => router.push(`/article/${article.id}`)}>
                <Grid
                  alignItems="center"
                  sx={{
                    paddingBottom: 1,
                    paddingTop: 1,
                  }}
                  container={true}
                  item={true}
                >
                  <Grid item={true} xs={1}>
                    <Typography
                      color="secondary"
                      display="inline"
                      variant="h5"
                      sx={{
                        fontStyle: 'italic',
                        marginRight: 1,
                      }}
                    >
                      {`${index + 1} `}
                    </Typography>
                  </Grid>
                  <Grid
                    container={true}
                    item={true}
                    xs={article.thumbnail || article.thumbnailUrl ? 8 : 11}
                    spacing={article.thumbnail || article.thumbnailUrl ? 1 : 0}
                  >
                    <Box pl={1} pr={1}>
                      <Typography>{article.title}</Typography>
                    </Box>
                  </Grid>
                  {(article.thumbnail || article.thumbnailUrl) && (
                    <Grid item={true} xs={3}>
                      <Image
                        article={article}
                        styleImage={{
                          height: 0,
                          maxHeight: '80px',
                          paddingTop: '56.25%', // 16:9
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
                <Divider />
              </CardActionArea>
            </Card>
          ))
        : filteredCatalogs.map((catalog, index) => (
            <Card key={index} elevation={0}>
              <CardActionArea
                onClick={() => router.push(`/catalog/${catalog.companyId}/${catalog.catalogId}`)}
              >
                <Grid
                  alignItems="center"
                  sx={{
                    paddingBottom: 1,
                    paddingTop: 1,
                  }}
                  container={true}
                  item={true}
                >
                  <Grid item={true} xs={1}>
                    <Typography
                      color="secondary"
                      display="inline"
                      variant="h5"
                      sx={{
                        fontStyle: 'italic',
                        marginRight: 1,
                      }}
                    >
                      {`${index + 1} `}
                    </Typography>
                  </Grid>
                  <Grid
                    container={true}
                    item={true}
                    xs={Array.isArray(catalog.thumbnailUrls) ? 8 : 11}
                    spacing={Array.isArray(catalog.thumbnailUrls) ? 1 : 0}
                  >
                    <Box pl={1} pr={1}>
                      <Typography>{catalog.title}</Typography>
                    </Box>
                  </Grid>
                  {catalog.thumbnailUrls && Array.isArray(catalog.thumbnailUrls) && (
                    <Grid item={true} xs={3}>
                      <img style={{ width: '100%' }} src={catalog.thumbnailUrls[0].url} />
                    </Grid>
                  )}
                </Grid>
                <Divider />
              </CardActionArea>
            </Card>
          ))}
    </Container>
  )
}

export default Ranking
