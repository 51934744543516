import React from 'react'
import Router from 'next/router'
import { Box, Button, Typography } from '@mui/material/'
import { Graph } from '@containers/Market/Graph'
import { Market } from '@services/chematels/models/market'

type Props = {
  market: Market
}

export function MarketView(props: Props) {
  const filteredMarketData = [...props.market.marketData].slice(-15)

  return (
    <Box mt={4}>
      <Typography sx={{ fontWeight: 'bold' }} variant="h6">
        {`市況情報 - ${props.market.marketMeta.title}`}
      </Typography>
      <Graph title={props.market.marketMeta.title} market={filteredMarketData} />
      <Button
        sx={{
          color: 'black',
          border: '1px solid rgba(0, 0, 0, 0.23)',
          padding: '5px 15px',
          mt: 2,
          mb: 3,
        }}
        fullWidth={true}
        onClick={() => {
          Router.push('/market')
        }}
      >
        全93種の市況情報を確認する
      </Button>
    </Box>
  )
}
