import React from 'react'
import { Container as ContainerUI, useMediaQuery } from '@mui/material/'
import { styled, useTheme } from '@mui/material/styles'

const PREFIX = 'Container'

const classes = {
  container: `${PREFIX}-container`,
  containerLeft: `${PREFIX}-containerLeft`,
  containerRight: `${PREFIX}-containerRight`,
  containerMd: `${PREFIX}-containerMd`,
}

const StyledContainerUI = styled(ContainerUI)(({ theme }) => ({
  [`&.${classes.container}`]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },

  [`&.${classes.containerLeft}`]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
  },

  [`&.${classes.containerRight}`]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(2),
    },
  },

  [`&.${classes.containerMd}`]: {
    marginTop: theme.spacing(2),
    minHeight: '65vh',
  },
}))

export const Container: React.FC<{ children: React.ReactNode }> = (props) => (
  <StyledContainerUI className={classes.container} disableGutters={true} maxWidth="md">
    <>{props.children}</>
  </StyledContainerUI>
)

export const ContainerLeft: React.FC<{ children: React.ReactNode }> = (props) => (
  <StyledContainerUI className={classes.containerLeft} disableGutters={true} maxWidth="md">
    <>{props.children}</>
  </StyledContainerUI>
)

export const ContainerRight: React.FC<{ children: React.ReactNode }> = (props) => (
  <StyledContainerUI className={classes.containerRight} disableGutters={true} maxWidth="md">
    <>{props.children}</>
  </StyledContainerUI>
)

export const ContainerMd: React.FC<{ children: React.ReactNode }> = (props) => {
  const theme = useTheme()
  const maxWidth = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <StyledContainerUI className={classes.containerMd} disableGutters={maxWidth} maxWidth="md">
      <>{props.children}</>
    </StyledContainerUI>
  )
}
