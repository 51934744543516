import React from 'react'
import { useRouter } from 'next/router'
import truncate from 'truncate'
import { Theme } from '@mui/material/styles'
import { Card, CardActionArea, CardContent, CardMedia, Divider, Typography } from '@mui/material/'
import { CatalogTopFragment } from '@services/cloud/client'

type Props = {
  catalog: CatalogTopFragment | null
}

export function CatalogList(props: Props) {
  const router = useRouter()

  if (!props.catalog) {
    return null
  }

  const thumbnailUrl =
    props.catalog?.thumbnailUrls?.[0]?.url ||
    'https://chematels.s3-ap-northeast-1.amazonaws.com/thumb.png'

  return (
    <Card sx={{ mt: 1 }} elevation={0} variant="outlined">
      <CardActionArea
        onClick={() =>
          router.push(`/catalog/${props?.catalog?.companyId}/${props?.catalog?.catalogId}`)
        }
      >
        {Array.isArray(props.catalog?.thumbnailUrls) && props.catalog?.thumbnailUrls.length ? (
          <CardMedia
            sx={{ height: 'auto', width: '100%', objectFit: 'scale-down', p: 1 }}
            component="img"
            image={thumbnailUrl}
          />
        ) : (
          <>
            <CardMedia
              sx={{
                height: 'auto',
                width: '100%',
                objectFit: 'scale-down',
                p: 1,
              }}
              component="img"
              image={thumbnailUrl}
            />
            <Divider />
          </>
        )}
        <CardContent
          sx={{
            borderTop:
              props.catalog?.thumbnailUrls && props.catalog?.thumbnailUrls[0]?.url
                ? (theme: Theme) => `1px solid ${theme.palette.grey[300]}`
                : {},
          }}
        >
          <Typography variant="body2">{props?.catalog?.title}</Typography>
          <Typography color="textSecondary" variant="body2">
            {truncate(props?.catalog?.company?.name || '', 13)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default CatalogList
