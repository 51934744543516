import React, { useContext, useEffect, useState } from 'react'
import { CarouselContext } from 'pure-react-carousel'
import { MobileStepper } from '@mui/material/'

type Props = {
  totalSlides: number
}

export function CarouselDots(props: Props) {
  const carouselContext = useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide)
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)

    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  return (
    <MobileStepper
      activeStep={currentSlide}
      sx={{ backgroundColor: 'white' }}
      steps={props.totalSlides}
      variant="dots"
      position="static"
      nextButton={<div />}
      backButton={<div />}
    />
  )
}
