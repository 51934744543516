import React from 'react'
import { useRouter } from 'next/router'
import { AppBar, Container, Tabs, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'

type Props = {
  centered: boolean
  disableGutters: boolean
  children: React.ReactNode
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false
  variant: 'standard' | 'scrollable' | 'fullWidth'
  mobilVariant: 'standard' | 'scrollable' | 'fullWidth'
}

function ScrollableTabsButtonAuto(props: Props) {
  const router = useRouter()
  const theme = useTheme()
  const isPcScreen = useMediaQuery(theme.breakpoints.up('sm'))

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    router.push(newValue)
  }

  return (
    <AppBar position="static" color="default" elevation={0} variant="outlined">
      <Container disableGutters={props.disableGutters} maxWidth={props.maxWidth}>
        <Tabs
          value={router.pathname}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant={isPcScreen ? props.variant : props.mobilVariant}
          centered={props.centered}
        >
          {props.children}
        </Tabs>
      </Container>
    </AppBar>
  )
}

export default ScrollableTabsButtonAuto
