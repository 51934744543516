import React, { useContext } from 'react'
import dayjs from 'dayjs'
import Link from 'next/link'
import { useRouter } from 'next/router'
import numeral from 'numeral'
import {
  Box,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import { NewsContext } from '@contexts/index'
import { getValueColor } from '@libs/getMarketValueColor'
import { MarketDataMonthToMonthBasis } from '@services/chematels/models/market'

type MarketTableList = {
  product: MarketDataMonthToMonthBasis
}

const MarketTableRow = (props: MarketTableList) => {
  const router = useRouter()

  return (
    <TableRow hover onClick={() => router.push(`/market/${props.product.name}`)}>
      <TableCell align="left">
        <Link href={`/market/${props.product.name}`}>
          <a>
            <Typography variant="body2">{props.product.productName}</Typography>
          </a>
        </Link>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {`${
            props.product.currentValue ? numeral(props.product.currentValue).format('0,0') : '-'
          } ${props.product.unit}`}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2" color={getValueColor(props.product.sign)}>
          {`${props.product.sign}${numeral(Math.abs(props.product.diffValue)).format('0,0')}`}
        </Typography>
      </TableCell>
      <TableCell align="right">
        <Typography variant="body2">
          {`${dayjs(props.product.publishDate).format('YY/MM')}`}
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export function MarketTableSidebar() {
  const includeMarketTableDataList = [
    'naphtha',
    'phosphorus',
    'hydrogen_fluoride',
    'methanol',
    'ethyl_acetate',
  ]
  const { marketDataMonthToMonthBasisList } = useContext(NewsContext)

  if (!marketDataMonthToMonthBasisList) {
    return null
  }

  return (
    <Paper sx={{ mt: 1 }} elevation={0} variant="outlined">
      <Container sx={{ mt: 1 }}>
        <Typography align="center" sx={{ fontWeight: 'bold' }} gutterBottom={true} variant="h6">
          市況情報
        </Typography>
        <Table sx={{ width: '100%' }}>
          <TableBody>
            {marketDataMonthToMonthBasisList
              .filter((it) => includeMarketTableDataList.includes(it.name))
              .map((it, index) => (
                <MarketTableRow key={index} product={it} />
              ))}
          </TableBody>
        </Table>
      </Container>
      <Box pt={2} pb={2}>
        <Link href="/market">
          <a>
            <Typography textAlign="center">市況一覧を確認</Typography>
          </a>
        </Link>
      </Box>
    </Paper>
  )
}
