import React from 'react'
import { Tab } from '@mui/material'
import Tabs from '@components/organisms/TabPanel'

export const NewsTabs = () => (
  <Tabs
    centered={false}
    disableGutters={true}
    maxWidth="lg"
    variant="fullWidth"
    mobilVariant="scrollable"
  >
    <Tab label="トップ" value="/" />
    <Tab label="特集" value="/stories" />
    <Tab label="市況" value="/market" />
    <Tab label="カタログ" value="/catalogs" />
  </Tabs>
)
