import React from 'react'
import { useRouter } from 'next/router'
import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material/'
import { useIsThumbnail } from '@hooks/useIsThumbnail'
import { ArticlesFragment } from '@services/graphcms/client'
import { Date } from './Date'
import { Image } from './Image'

type Props = {
  article: ArticlesFragment
  fullYear: boolean
  isThumbnail: boolean
  variant: 'elevation' | 'outlined'
}

export function FeedList(props: Props) {
  const router = useRouter()
  const isThumbnail = useIsThumbnail(props.article)

  return (
    <Card
      sx={{
        height: '100%',

        '&:hover': {
          textDecoration: 'underline',
        },
      }}
      elevation={0}
      variant={props.variant}
    >
      <CardActionArea
        onClick={() => router.push(`/article/${props.article.id}`)}
        data-testid="feed-article-list"
      >
        <CardContent>
          <Grid container={true} spacing={1}>
            <Grid item={true} xs={isThumbnail ? 8 : 12}>
              <Typography sx={{ fontWeight: 'bold' }} variant="subtitle1">
                {props.article.title}
              </Typography>
              <Date article={props.article} fullYear={props.fullYear} />
            </Grid>
            {props.isThumbnail && isThumbnail && (
              <Grid item={true} xs={4}>
                <Image
                  article={props.article}
                  styleImage={{
                    height: 0,
                    maxHeight: '80px',
                    paddingTop: '56.25%', // 16:9
                  }}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}
