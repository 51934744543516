import React from 'react'
import { Box, Container } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { StyledTextA } from '@components/atoms/StyledTextA'
import { Market } from './Market'

export const MarketHeadline = () => (
  <StyledTextA>
    <Box
      sx={{
        width: '100%',
        minHeight: '2em',
        height: '2em',
        pt: 1,
        pb: 1,
        borderBottom: (theme: Theme) => `1px solid ${theme.palette.grey[300]}`,
      }}
    >
      <Container>
        <Market />
      </Container>
    </Box>
  </StyledTextA>
)
