import React from 'react'
import Router from 'next/router'
import { Box, Button, Container, Divider, Grid, Typography } from '@mui/material'
import { NewsTabs } from '@components/common/NewsTabs'
import { ContainerLeft, ContainerRight } from '@components/atoms/Container'
import AdFeedCard from '@components/organisms/Ad/AdFeedCard'
import AdFeedList from '@components/organisms/Ad/AdFeedList'
import { FeedList } from '@components/organisms/Article/FeedList'
import { CatalogList } from '@components/organisms/CatalogList'
import { CatalogTopFragment, FirstDepthCatalogCategoriesFragment } from '@services/cloud/client'
import { useGetArticlesQuery, AdDeliveryDestination } from '@services/graphcms/client'
import { Market, MarketDataMonthToMonthBasis } from '@services/chematels/models/market'
import { MarketHeadline } from '../MarketHeadline'
import { Informations } from '../Informations'
import { MainCarousel } from './MainCarousel'
import { MarketView } from './MarketView'
import { MarketTableSidebar } from './MarketTable'
import RankingTab from '../RankingTab'

type Props = {
  firstDepthCategories: FirstDepthCatalogCategoriesFragment[]
  catalogs: CatalogTopFragment[]
  market: Market
  marketDataMonthToMonthBasisList: MarketDataMonthToMonthBasis[]
  pickupCatalogs: CatalogTopFragment[]
}

export function Top(props: Props) {
  const { data } = useGetArticlesQuery({
    variables: {
      skip: 0,
      first: 14,
    },
  })

  const feeds = data && data?.articles ? [...data.articles] : []

  return (
    <>
      <NewsTabs />
      <MarketHeadline />
      <Container disableGutters={true}>
        <Grid container={true}>
          <Grid item xs={12} sm={8}>
            <ContainerLeft>
              {data && data?.articles && data.articles.length > 0 && (
                <>
                  {data && Array.isArray(data.stories) && data.stories.length > 0 && (
                    <MainCarousel stories={data.stories} />
                  )}
                  <AdFeedList destination={AdDeliveryDestination.Top1} />
                  <Grid sx={{ mt: 1 }} container={true} spacing={1}>
                    {feeds.map((article, index) => {
                      if (!article) return null

                      return (
                        <Grid key={article.id} item={true} xs={12} sm={12} md={6} lg={6} xl={6}>
                          <FeedList
                            key={index}
                            fullYear={false}
                            isThumbnail={true}
                            article={article}
                            variant="elevation"
                          />
                          <Divider />
                        </Grid>
                      )
                    })}
                  </Grid>
                  <Button
                    sx={{
                      color: 'black',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      padding: '5px 15px',
                      mt: 2,
                      mb: 3,
                    }}
                    fullWidth={true}
                    onClick={() => {
                      Router.push('/news/latest?page=2')
                    }}
                  >
                    もっと読む
                  </Button>
                  <AdFeedList destination={AdDeliveryDestination.Top2} />
                  <MarketView market={props.market} />
                </>
              )}
            </ContainerLeft>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ContainerRight>
              <Box sx={{ mt: 1 }}>
                <AdFeedCard destination={AdDeliveryDestination.Top3} />
              </Box>
              <Box sx={{ mt: 1 }}>
                <MarketTableSidebar />
              </Box>
              <RankingTab />
              <Informations />
            </ContainerRight>
          </Grid>
        </Grid>
        <Divider sx={{ mt: 2, mb: 2 }} variant="middle" />
        <Box pt={1} pb={1}>
          <Typography sx={{ fontWeight: 'bold' }} variant="h6">
            カタログ新着
          </Typography>
          <Grid container={true} item={true} spacing={1}>
            {props?.catalogs &&
              Array.isArray(props.catalogs) &&
              props.catalogs.map((catalog) => (
                <Grid key={catalog?.catalogId} item={true} xs={6} sm={3} md={2}>
                  <CatalogList catalog={catalog} />
                </Grid>
              ))}
          </Grid>
        </Box>
        {props.pickupCatalogs &&
          Array.isArray(props.pickupCatalogs) &&
          props.pickupCatalogs.length > 0 && (
            <Box pt={1} pb={1}>
              <Typography sx={{ fontWeight: 'bold' }} variant="h6">
                ピックアップカタログ
              </Typography>
              <Grid container={true} item={true} spacing={1}>
                {props.pickupCatalogs.map((catalog) => (
                  <Grid key={catalog?.catalogId} item={true} xs={6} sm={3} md={2}>
                    <CatalogList catalog={catalog} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
      </Container>
    </>
  )
}
