export const getValueColor = (sign: string) => {
  switch (sign) {
    case '+': {
      return 'green'
    }
    case '-': {
      return 'red'
    }
    default: {
      return 'black'
    }
  }
}
