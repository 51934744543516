import React, { useContext } from 'react'
import Link from 'next/link'
import numeral from 'numeral'
import { Grid, Hidden, Typography } from '@mui/material'
import { NewsContext } from '@contexts/index'

export function Market() {
  const { marketDataMonthToMonthBasisList } = useContext(NewsContext)

  if (!marketDataMonthToMonthBasisList) {
    return null
  }

  const crude = marketDataMonthToMonthBasisList.find((it) => it.name === 'crude')
  const naphtha = marketDataMonthToMonthBasisList.find((it) => it.name === 'naphtha')
  const polyphosphoric = marketDataMonthToMonthBasisList.find((it) => it.name === 'polyphosphoric')
  const hydrogenFluoride = marketDataMonthToMonthBasisList.find(
    (it) => it.name === 'hydrogen_fluoride',
  )

  return (
    <Grid container={true}>
      <Grid container={true} item={true} xs={7} sm={9} md={10} justifyContent="space-between">
        <Link href={`/market/${crude?.name}`}>
          <a>
            <Typography
              sx={{
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              display="block"
              variant="caption"
            >
              {`${crude?.productName} ${numeral(crude?.currentValue).format('0,0')} JPY/KG`}
            </Typography>
          </a>
        </Link>
        <Hidden smDown={true} initialWidth="md">
          <Link href={`/market/${naphtha?.name}`}>
            <a>
              <Typography
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                display="block"
                variant="caption"
              >
                {`${naphtha?.productName} ${numeral(naphtha?.currentValue).format('0,0')} JPY/KL`}
              </Typography>
            </a>
          </Link>
        </Hidden>
        <Hidden smDown={true} initialWidth="md">
          <Link href={`/market/${polyphosphoric?.name}`}>
            <a>
              <Typography
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                display="block"
                variant="caption"
              >
                {`${polyphosphoric?.productName} ${numeral(polyphosphoric?.currentValue).format(
                  '0,0',
                )} JPY/KG`}
              </Typography>
            </a>
          </Link>
        </Hidden>
        <Hidden mdDown={true} initialWidth="md">
          <Link href={`/market/${hydrogenFluoride?.name}`}>
            <a>
              <Typography
                sx={{
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
                display="block"
                variant="caption"
              >
                {`${hydrogenFluoride?.productName}  ${numeral(
                  hydrogenFluoride?.currentValue,
                ).format('0,0')} JPY/KG`}
              </Typography>
            </a>
          </Link>
        </Hidden>
      </Grid>
      <Grid item={true} xs={5} sm={3} md={2}>
        <Link href="/market">
          <a>
            <Typography
              align="right"
              sx={{
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              display="block"
              variant="caption"
            >
              市況情報一覧 〉
            </Typography>
          </a>
        </Link>
      </Grid>
    </Grid>
  )
}
