import React from 'react'
import dayjs from 'dayjs'
import numeral from 'numeral'
import { Line } from 'react-chartjs-2'

type Props = {
  title: string
  market: any
}

export function Graph(props: Props) {
  if (!Array.isArray(props.market)) {
    return null
  }

  const reversedMarket = [...props.market]
  const filterMarket = reversedMarket.filter((market) => market.value > 0)
  const graphData: any = {
    labels: filterMarket.map((row: any) => dayjs(`${row.year}-${row.month}-01`).format('YY/MM')),
    datasets: [
      {
        data: filterMarket.map((row: any) => row.value),
        label: '価格',
        fill: true,
        lineTension: 0.1,
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      },
    ],
  }
  const options: any = {
    responsive: true,
    animation: false,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            callback: (label: number) => numeral(label).format('0,0'),
          },
        },
      ],
    },
  }

  return (
    <div>
      <Line data={graphData} options={options} width={1053} height={400} />
    </div>
  )
}
