import { useEffect, useState } from 'react'
import { ArticlesFragment } from '@services/graphcms/client'

export function useIsThumbnail(article: ArticlesFragment) {
  const [isThumbnail, setIsThumbnail] = useState(article?.thumbnail || article?.thumbnailUrl)
  useEffect(() => {
    setIsThumbnail(article?.thumbnail || article?.thumbnailUrl)
  }, [article?.thumbnail, article?.thumbnailUrl])

  return isThumbnail
}
